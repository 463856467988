<template>
  <v-dialog v-model="modalData.dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        New Item
      </v-btn> -->
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <!-- :rules="nameRules" -->
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  label="Country"
                  :id="dynamicID"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.name']
                  "
                  @keyup="
                    $emit(
                      'resetErrorData',
                      'translations.' + selectedLocale.lang + '.name'
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.id"
                  label="Id"
                  disabled
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.locale"
                  :items="languages"
                  item-text="name"
                  item-value="lang"
                  label="Language"
                  :rules="localeRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.currency_id"
                  :items="currencyCollection"
                  item-text="sign"
                  item-value="id"
                  label="Currency"
                  :rules="currencyRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox v-model="formModel.eu" label="EU"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox
                  v-model="formModel.status"
                  label="Active"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleSaveModalForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import {
  FETCH_CURRENCY,
  CURRENCY_COLLECTION,
} from "@/core/services/store/currency.module.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = {
  id: "",
  locale: "",
  eu: 1,
  currency_id: "EUR",
  translations: {},
};

export default {
  name: "CountryForm",
  props: ["modalData", "permissions", "loader", "messages", "selectedLocale"],
  data() {
    return {
      languages: i18nService.languages,
      // selectedLocale: i18nService.languages.find((item) => {
      //   return item.lang == i18nService.getActiveLanguage();
      // }),
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      nameRules: [
        (v) => !!v || "Country is required",
        (v) => v.length > 2 || "Country must be min 3 characters",
      ],
      localeRules: [(v) => !!v || "Language is required"],
      currencyRules: [(v) => !!v || "Currency is required"],
    };
  },
  computed: {
    ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      // return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString());

      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    formTitle() {
      return this.modalData.editedIndex === -1 ? "New Country" : "Edit Country";
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        this.setTranslatedAttributes();
      },
    },
  },
  methods: {
    ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    permissionCan(action) {
      return this.permissions.find((x) => x.name == "system.country." + action);
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
  },
  async mounted() {
    this.fetchCurrency();
  },
};
</script>
